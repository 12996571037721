import { ApiConfig } from './../config/api-config';
import { ElectronicCatalogModel } from './model/electronic-catalog/electronic-catalog.model';
import { ResultModel } from './model/result-model';
import { Observable } from 'rxjs';
import { LanguageService } from './language.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BaseDataService } from './base-data.service';

@Injectable({
  providedIn: 'root'
})
export class ElectronicCatalogService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  constructor(
    private http: HttpClient,
    private languagesvc: LanguageService,
    private datepipe: DatePipe,
    private baseDatasvc: BaseDataService
  ) { }

  getData(): Observable<ResultModel<ElectronicCatalogModel>> {

    const apiUrl = ApiConfig.APIDomain + ApiConfig.API.Index.ElectronicCatalog;

    let nowDate = this.datepipe.transform(new Date(), 'yyyy/MM/dd').toString();

    if (this.baseDatasvc.flightData && this.baseDatasvc.flightData.loadDate) {
      nowDate = this.datepipe.transform(this.baseDatasvc.flightData.fltDepLclDate, 'yyyy/MM/dd').toString();
    }

    const condition = {
      LngNo: this.languagesvc.nowLangNo,
      FlightDate: nowDate
    };

    return this.http.post<ResultModel<ElectronicCatalogModel>>(apiUrl, condition, this.httpOptions);
  }
}
